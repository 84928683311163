@import '../../variables.scss';
@import '../global.module.scss';

.formContainer {
	color: $black_43;
	position: relative;

	> * {
		margin-bottom: 14px;
	}

	> header {
		background-color: $white;
		position: sticky;
		top: 0;
		z-index: 10;
	}

	header {
		margin-bottom: 4px;
	}

	label {
		color: $black_43;
		padding: 0;
	}

	.formRow {
		align-items: baseline;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0;
		> * {
			&:not(:first-child) {
				margin-left: 10px;
			}
		}
	}

	.meta {
		align-items: center;
		color: $grey_9a;
		background-color: $grey_f7;
		padding: 4px 20px;
		display: grid;
		grid-template-columns: 1fr auto;
		margin: 5px -20px;
		align-items: start;
		.topLeft {
			& > * {
				float: left;
				height: 33px;
			}
		}
		.topRight {
			& > * {
				height: 33px !important;
			}
			.showAttendeesButton {
				margin-right: -5px;
			}
		}
		.bottomLeft > * {
			height: 33px;
		}
	}

	.buttonContainer {
		display: flex;
		justify-content: flex-end;
		margin-top: 17px;

		> * {
			margin-left: 15px;
		}

		> label {
			align-self: center;
			font-weight: 400;
		}
	}

	div[class~='ms-TextField-wrapper'] label,
	.label {
		box-sizing: border-box;
		box-shadow: none;
		display: block;
		font-size: 14px;
		font-weight: 600;
		margin: 0;
		overflow-wrap: break-word;
		padding: 0 0 8px;
	}

	.showAttendeesButton,
	.showAttendeesButton:hover {
		height: 20px;
		* {
			color: $black_43;
		}
	}

	h1 {
		align-items: center;
		font-size: 16px;
		font-weight: 600;
		display: flex;
		justify-content: flex-start;
		margin: 0;
		width: calc(100% - 53px);
	}

	.stoppedContainer,
	.playButtonContainer,
	.stopButtonContainer {
		box-sizing: border-box;
		height: 31px;
		margin-right: 12px;
		width: 31px;
		&.disabled {
			background-image: url('../../images/play-circle-outline-disabled.svg'),
				url('../../images/stop-circle-outline-disabled.svg');
		}

		button {
			height: 31px;
			padding: 0;
			width: 31px;

			> span {
				margin-left: -1px;
			}
		}
	}

	.playButtonContainer,
	.stopButtonContainer {
		background-image: url('../../images/play-circle-outline.svg'), url('../../images/stop-circle-outline.svg');
		background-position: center center, -1000px -1000px;
		background-repeat: no-repeat, no-repeat;
		background-size: 30px;
	}

	.stopButtonContainer {
		background-position: -1000px -1000px, center center;
	}

	.stoppedContainer {
		:global {
			.ms-Button--command .ms-Button-flexContainer .ms-Icon,
			.ms-Button--command:hover .ms-Button-flexContainer .ms-Icon {
				font-size: 30px;
				font-weight: bold;
				color: $blue_18;
				margin-left: 0px;
			}
		}
	}

	.memberList {
		list-style: none;
		margin: 0;
		padding: 0;

		.memberItem {
			padding: 0 32px 10px 0;

			:global(.ms-Persona-primaryText),
			:global(.ms-Persona-secondaryText) {
				color: $black_43;
			}
		}
	}

	.numField {
		background-color: $grey_f3;
		border: 0;
		border-bottom: 2px solid $grey_f3;
		line-height: 28px;
		padding: 0 0 0 6px;
		width: 42px;
	}

	header {
		:global {
			.ms-Button--action .ms-Button-flexContainer .ms-Icon,
			.ms-Button--action:hover .ms-Button-flexContainer .ms-Icon {
				color: $black_43;
			}
		}
	}

	.moreImageButton {
		height: 43px;
		margin-right: 12px;
		:global(.ms-Button-flexContainer) {
			background-image: url('../../images/more-icon.svg'), url('../../images/more-icon-hover.svg');
			background-position: center center, -1000px -1000px;
			background-repeat: no-repeat, no-repeat;
			height: 32px;
			width: 32px;
			i {
				display: none;
			}
		}
		:global(.ms-Button) {
			height: 43px;
		}

		&:hover {
			:global(.ms-Button-flexContainer) {
				background-position: -1000px -1000px, center center;
			}
		}
	}

	.multiVotesInputContainer {
		align-items: center;
		display: flex;
		justify-content: flex-start;
		.label {
			margin-left: 8px;
			padding: 0;
		}
	}

	.emptyMoreOptionsContainer {
		width: 38px;
	}

	.inputHeader,
	.voteWeightHeader,
	.deleteHeader,
	.pinHeader {
		> span {
			display: block;
			margin-right: 12px;
			width: 200px;
		}
	}

	.inputHeader,
	.voteWeightHeader {
		box-sizing: border-box;
	}
	.inputHeader {
		background-color: $white;
		padding: 0 0 0 9px;
		position: sticky;
		top: 0px;
		z-index: 10;
	}

	.pinHeader {
		background-color: $white;
		width: 60px;
		position: sticky;
		top: 0px;
		z-index: 10;
	}
	.voteWeightHeader {
		background-color: $white;
		position: sticky;
		top: 0;
		z-index: 10;
	}

	.voteWeightHeader,
	.voteWeightField {
		width: 120px;

		&.percent {
			/* Chrome, Safari, Edge, Opera */
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			input[type='number'] {
				-moz-appearance: textfield;
			}
		}
	}

	.deleteHeader {
		background-color: $white;
		width: 34px;
		position: sticky;
		top: 0;
		z-index: 10;
	}

	.headerRow {
		margin: 20px 0;
	}

	.buttonRow {
		justify-content: flex-end;
	}

	.panelHeader {
		display: flex;
		align-items: center;
		gap: 0 10px;
		flex-wrap: wrap;
		width: 100%;

		.lastChanged {
			font-size: 12px;
			font-weight: 400;
			display: contents;
		}
	}
	.panelButtons {
		margin: 0;
	}
	.closeIcon,
	.closeIcon:hover {
		margin-right: 0;

		:global {
			.ms-Button-flexContainer .ms-Icon {
				margin-right: 0;
			}
		}
	}

	.voteWeightSum {
		font-weight: bold;
		height: 18px;
		margin: 4px 8px 0 0;
		padding: 7px 0 7px 7px;

		&.invalid {
			background-color: $red_e4;
			color: $white;
			opacity: 0.8;
		}

		span:last-child {
			float: right;
			margin-right: 8px;
		}
	}

	.voteWeightSumHint {
		padding-top: 5px;
		> * {
			cursor: pointer;
		}
	}

	.answersList {
		margin-left: -20px;
		.label {
			padding: 8px 0 0 20px;
		}
		> div {
			margin-left: 20px;
			padding-top: 8px;
		}
	}
	.answerOptionsEntityList {
		td {
			padding: 8px 0 0 0;
			&:first-child {
				width: 20px;
			}
		}
		:global {
			.ms-TextField-fieldGroup {
				margin: 0;
			}
			.ms-TextField-suffix {
				background: transparent;
				padding: 0;
			}
		}
		.newAnswerOption {
			padding-top: 0;
			button {
				margin-right: 0px;
			}
		}
		.entityListDeleteButtonContainer {
			width: 34px;
			height: 32px;

			button {
				height: 32px;
			}
		}
	}
	.errorColor,
	.errorMessage,
	.errorPanel {
		color: $red_c7;
	}
	.errorMessage,
	.errorPanel {
		font-size: 12px;
	}
	.errorPanel {
		padding: 4px 8px;
	}
	.fixedHeader {
		background-color: $white;
		position: fixed;
		top: 18px;
		width: calc(100% - 40px);
		z-index: 10;
	}
	.scrollContent {
		box-sizing: border-box;
		height: calc(100vh - 272px);
		overflow-y: auto;
		margin-top: 240px;
	}
	:global {
		.ms-DatePicker .ms-TextField-field {
			color: $black_43;
			&:focus::after {
				display: none;
			}
		}
		.ms-DatePicker .ms-DatePicker-event--with-label {
			color: $violet_62;
		}
	}
}

.questionKindSelector {
	:global {
		.ms-ChoiceFieldGroup-flexContainer {
			justify-content: space-between;
			gap: 15px;
			padding: 8px 0 0;
		}

		.ms-ChoiceField-wrapper {
			height: 65px;
			width: 100%;
		}

		.ms-ChoiceField {
			background-color: $grey_f3;
			border-top: solid 1px $grey_f3;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			width: 22%;
			@media only screen and (max-width: 1440px) {
				width: 46%;
			}
		}

		.ms-ChoiceField-innerField {
			box-sizing: content-box;
			top: 8px;
		}

		.ms-ChoiceField-labelWrapper {
			font-weight: 600;
			max-width: 100%;
			top: 4px;
		}

		.ms-ChoiceFieldLabel {
			font-size: 12px;
		}

		.ms-ChoiceField-iconWrapper {
			font-size: 20px;
		}

		.ms-ChoiceField-field {
			border-top: solid 1px $grey_f3;
			border-left: 0;
			border-right: 0;
			border-bottom: solid 5px $grey_f3;
			border-radius: 4px;
			height: 64px;
			&:hover {
				color: $black_43;
				.ms-ChoiceFieldLabel {
					color: $black_43;
				}
			}
		}
		.ms-ChoiceField-field.is-checked {
			border-bottom: solid 5px $violet_62;
			color: $violet_62;
			&:hover {
				color: $violet_62;
				.ms-ChoiceFieldLabel {
					color: $violet_62;
				}
			}
		}

		.ms-ChoiceField-field::before,
		.ms-ChoiceField-field::after {
			display: none;
		}
	}
	.infoIcon {
		position: absolute;
		top: 1px;
		right: 2px;
		height: 16px;
		width: 16px;
		z-index: 9;
		i {
			font-size: 14px;
		}
	}
}

.infoBox {
	border: solid 1px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	padding: 6px 12px;

	> * {
		display: inline-flex;
		padding: 2px;
	}

	> span {
		padding: 2px 5px 5px;
	}
}

:global {
	.is-active {
		.ms-TextField-suffix {
			border-bottom: 2px solid $violet_62;
			margin-top: 2px;
		}
	}
}

:global {
	.ms-Panel-navigation {
		display: none;
	}
	.ms-Panel-content {
		padding: 0 20px;
	}
}

.rotateFlipIcon i {
	transform: rotate(-180deg) scaleX(-1);
}

:global {
	.ms-Toggle {
		.ms-Label i {
			padding-right: 5px;
		}
		.ms-Toggle-background {
			border-color: $violet_62;
		}
		.ms-Toggle-thumb {
			background-color: $violet_62;
		}
		&.is-checked {
			.ms-Toggle-background {
				border-color: $violet_62;
				background-color: $violet_62;
			}
			.ms-Toggle-thumb {
				background-color: #ffffff;
			}
		}
		&:not(:first-child) {
			margin-left: 5px;
		}
	}
	.ms-Toggle-label {
		line-height: 32px;
		word-break: break-word;
	}
	.ms-TextField-field {
		border: 0;
		background-color: $grey_f3;
	}
	textarea:focus,
	input:focus {
		border-bottom: 2px solid $violet_62;
		padding-top: 2px;
		outline: 0;
		&[aria-invalid='true'] {
			border-bottom-color: $red_c7;
		}
	}
	.ms-TextField-fieldGroup {
		border: 0;
		&::after {
			display: none;
		}
		input,
		textarea {
			color: $black_43;
		}
		input::placeholder,
		textarea::placeholder {
			color: $grey_85;
		}

		textarea {
			resize: none;
		}
	}
	:global(.ck-placeholder) {
		color: $grey_85;
	}
	.ms-TextField.is-disabled,
	.ms-Toggle.is-disabled,
	.ms-Button--primary.is-disabled {
		opacity: 0.5;
		input,
		textarea,
		.ms-Toggle-background {
			cursor: not-allowed;
		}
	}
}

.textResultContainer {
	column-count: 3;
	column-gap: 15px;
	width: 100%;
	.textResultOuter {
		width: 100%;
	}
	.textResult {
		width: calc(100% - 4px);
		border: 2px solid $violet_62;
		border-radius: 7px;
		display: inline-block;
		margin-top: 14px;
	}
	.textResultInner {
		overflow-wrap: break-word;
		padding: 10px 12px;
		.textResultInnerAttendeeName {
			font-style: italic;
			text-align: right;
		}
	}
}

.contextualMenuIcon {
	margin: 0 4px;
	width: 16px;
}

.contextualMenuItemText {
	margin: 0 4px;
}

.inlineIcon {
	color: $grey_9a;
}

.memberFormButton {
	* {
		color: $black_43;
	}
	:global {
		.ms-Button-flexContainer .ms-Button-textContainer .ms-Button-label {
			color: $black_43;
			&:hover {
				color: $violet_62;
				text-decoration: none;
			}
		}
	}
	&:hover * {
		color: $violet_62 !important;
	}
}
