@import '../../variables.scss';
.entityItem {
	margin: 0 0 11px 0;
	padding: 0;
	vertical-align: middle;
	&.droppableContainer {
		margin: 0 0 11px -19px;
	}

	& > .entityItemScrollIconContainer {
		height: 32px;
		line-height: 32px;
		margin: 0;
		padding: 0;
		text-align: center;
		vertical-align: bottom;
		width: 20px;
		> * {
			color: transparent;
		}
	}

	& > *:last-child {
		padding-right: 0;
	}

	:global {
		.ms-Button--action,
		.ms-Button--action:hover {
			.ms-Button-flexContainer .ms-Icon {
				color: $black_43;
			}
		}
	}

	.entityItemScrollIconContainer {
		&:hover {
			cursor: ns-resize;
		}
		+ :global(.ms-TextField) {
			padding: 0 15px;
		}
	}

	&:hover {
		.entityItemScrollIconContainer {
			> * {
				color: inherit;
			}
		}
	}
}
