@import '../../variables.scss';

.anchor{
	width: 32px;
	height: 32px;
}
.svg {
	height: 30px;
	margin-top: 1px;
	opacity: 0.9;
	width: 30px;
	.icons-default-fill {
		display: block;
		opacity: 0.9;
	}
	.icons-filled {
		display: none;
	}
}
.button {
	border-radius: 0;
	border-width: 0;
}
.tooltip {
	background-color: red;
}

.feedback,
.feedback:hover {
	font-size: 14px;
	line-height: 19px;
    height: 30px;
	letter-spacing: 0px;
	padding-right: 0;
	overflow: hidden;
    width: 35px;
	* {
		color: $black_43 !important;
	}
}
