@import './variables.scss';
.app {
	color: $black_43;
	font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
		'Helvetica Neue', sans-serif;
	font-size: 14px;
	background-color: $grey_f5;
	* {
		box-sizing: content-box;
	}
}
:global(.is-disabled) * {
	color: $grey_9a !important;
}
:global(.ms-Button--primary.is-disabled) * {
	color: $white !important;
}
:global(.votrInfoIcon i) {
	margin-top: 2px;
}
:global(.marginBottom8) {
	margin-bottom: 8px;
}
:global {
	.ms-TextField-errorMessage {
		color: $red_c7;
	}
}
