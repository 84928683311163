.container {
	justify-content: space-between;
	gap: 24px;
	font-weight: 600;
}
.formArea {
	display: flex;
	justify-content: space-between;
}

.formItemDate {
	margin: 8px 16px 8px 0;
	width: 90%;
}

.formItemTime {
	margin: 8px 0px 8px 16px;
	width: 90%;
}
