@import "../../variables.scss";
.uploadSuccess {
    color: $blue_18;
    display: none;
    padding: 16px 0;
    min-height: 45px;
    max-height: 12px;
    &.showSuccess {
        display: block;
    }
}

.uploadError {
    color: $red_e4;
    padding: 16px 0;
    display: none;
    min-height:45px;
    max-height: 12px;
    &.showError {
        display: block;
    }
}

.fileUploader {
    max-width: 80px;
    max-height: 80px;
    background-size: cover;
    background-size: contain;
    border-radius: 5%;
}

.uploadContainer {
    display: flex;
}

.imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    min-height: 85px;
    max-width: 85px;
    max-height: 85px;
    border: 1px dashed $grey_73;
    border-radius: 15%;
    cursor: pointer;

    label {
        cursor: pointer;
        text-align: center;
    }
}

.header {
    display: flex;
    h4 {
        margin-right: 5px;
        font-size: 14px;
        font-weight: 600;
    }
}

.logoContainer {
    display: inline-block;
}

.linkContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    padding-left: 18px
}

.button {
    max-height: 32px;

    * {
		color: $black_43;
	}
	:global {
		.ms-Button-flexContainer .ms-Icon.ms-Button-icon,
        .ms-Button-flexContainer .ms-Button-textContainer .ms-Button-label {
			color: $black_43;
			&:hover {
				color: $violet_62;
				text-decoration: none;
			}
		}
	}
	&:hover * {
		color: $violet_62 !important;
	}
}