@import '../../variables.scss';
@import '../molecules/Sidepanel.module.scss';

.subTitle {
    font-size: 14px;
    line-height: 19px;
    color: $black_43;
    display: flex;
    align-items: center;
    min-height: 60px;
    overflow: hidden;
}