@import '../../variables.scss';

.hubContainer {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	.hubFlexContainer {
		box-sizing: border-box;
		padding: 10px 10px 10px 20px;
		width: 100%;
	}
	.footer {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}

:global {
	.ms-Button {
		border-radius: 4px;
	}
	.ms-Button--command,
	.ms-Button--command:hover {
		padding-left: 0;
		.ms-Button-textContainer .ms-Button-label {
			color: $black_43;
			margin-left: 0;
			&:hover {
				text-decoration: none;
			}
		}
		.ms-Button-flexContainer .ms-Icon {
			color: $black_43;
			margin-right: 0;
		}
	}
	.ms-Button--action,
	.ms-Button--action:hover {
		padding-left: 0;
		.ms-Button-textContainer .ms-Button-label {
			color: $violet_62;
			margin-left: 0;
			&:hover {
				text-decoration: underline;
			}
		}
		.ms-Button-flexContainer .ms-Icon {
			color: $violet_62;
			margin-right: 8px;
		}
	}
	.ms-Button--primary {
		color: $white;
		border-color: transparent;
		background-color: $violet_62;
	}

	.ms-Button--primary:hover {
		background-color: #464775;
	}

	.ms-Button--default,
	.ms-Button--default:hover {
		border-color: $black_43;
		background-color: $white;
		.ms-Button-label,
		.ms-Icon {
			color: $black_43;
		}
		.ms-Icon-placeHolder {
			display: none;
		}
	}
	.ms-Button--default:hover {
		background-color: #edebe9;
	}

	i[data-icon-name='More'] {
		font-size: 24px;
		&:hover {
			font-weight: 800;
		}
	}

	// change context menu
	.ms-Callout.ms-ContextualMenu-Callout {
		border: 1px solid $grey_f3;
		border-radius: 4px;
		box-shadow: 0px 2px 4px 0px #00000029;
		margin-right: 2px;

		.ms-ContextualMenu-link {
			height: 38px;
			padding: 7px;
			&:hover {
				background-color: #e3e3f3;
			}
		}

		.ms-ContextualMenu-linkContent {
			height: 24px;
		}
	}

	// .ms-Panel-scrollableContent {
	// 	overflow-y: initial;
	// }
	// .ms-Panel-contentInner {
	// 	overflow: hidden;
	// }
}
