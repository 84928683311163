$themePrimary: #0f73ba;
$themeLighterAlt: #f3f8fc;
$themeLighter: #d0e5f4;
$themeLight: #aacfea;
$themeTertiary: #60a5d6;
$themeSecondary: #2581c2;
$themeDarkAlt: #0d67a8;
$themeDark: #0b578d;
$themeDarker: #084068;
$neutralLighterAlt: #e9eff8;
$neutralLighter: #e5ebf4;
$neutralLight: #dce2ea;
$neutralQuaternaryAlt: #cdd2da;
$neutralQuaternary: #c4c9d0;
$neutralTertiaryAlt: #bcc1c8;
$neutralTertiary: #92b2d1;
$neutralSecondary: #4172a3;
$neutralPrimaryAlt: #0e4378;
$neutralPrimary: #003366;
$neutralPrimary7F: #0033667f;
$neutralDark: #00274e;
$black: #001d39;
$white: #fff;
$blue_f0: #f0f6ff;
$blue_b9: #b9cfe5;
$blue_00: #009ed4;
$blue_18: #1893a0;
$blue_0f: #0f7681;
$blue_ee: #eef6f9;
$grey_81: #818183;
$grey_cd: #cddde3;
$black_06: #060606;
$blue_9f: #9fcfdf;
$blue_71: #71c0db;
$red_e4: #e40d2e;
$red_c7: #c72626;
$grey_f0: #f0f0f0;
$grey_f3: #f3f2f1;
$grey_f5: #f5f5f5;
$grey_73: #737373;
$grey_f7: #f7f7f7;
$grey_85: #858585;
$grey_9a: #9a9a9a;
$grey_ao: #A0A0A0;
$grey_72: #727272;
$black_43: #434343;
$black_21: #212121;
$yellow_b1: #b18800;
$violet_62: #6264a7;
$green_92: #92c353;
$grey_e3: #e3e3e3;
$grey_d1: #d1d1d1;

$tabletWidth: 1270px;
$mobileWidth: 800px;

@mixin centerForm {
	box-sizing: border-box;
	margin: auto;
	padding: 20px;
	position: relative;
	width: 1200px;
	@media only screen and (max-width: 1471px) {
		width: 75%;
	}
	@media only screen and (max-width: 735px) {
		width: 100%;
	}
}

@mixin for-phone-only {
	@media (max-width: 813px) {
		@content;
	}
}
