@import '../molecules/Sidepanel.module.scss';

.shareContent {
	display: flex;
	flex-direction: column;
	margin: 20px 0;
	.label {
		color: $black_43;
		font-weight: bold;
	}
	.qrContent {
		align-items: flex-start;
		background: $grey_f3;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		padding: 20px;
		.qrImage {
			box-sizing: border-box;
			height: 180px;
			padding-left: 20px;
			img {
				height: 180px;
				image-rendering: pixelated;
			}
		}
		.qrClipboard {
			background: transparent;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 180px;
		}
		.qrHintText {
			font-size: 12px;
		}
	}
	.urlContent {
		display: flex;
		.urlField {
			width: 100vw;
			:focus {
				border: 0px;
			}
		}
		.urlClipboard {
			display: flex;
			align-items: flex-end;
		}
	}
	.orContainer {
		padding: 25px 0;
	}
	:global {
		.ms-Button.ms-Button--icon {
			color: $violet_62;
		}
		.ms-Label {
			padding: 0 0 8px;
		}
	}
}
