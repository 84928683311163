@import '../../variables.scss';

.iconContainer {
	display: flex;
	align-items: center;
	max-width: 14px;

	& > i {
		cursor: pointer;
		color: $black_43;
	}
}
:global {
	:local(.iconContainer) {
		& + .ms-layer {
			display: none;
		}
	}
}

.callout {
	padding: 20px;
	line-height: 21px;
	width: 320px;
	:global {
		.ms-Callout-main {
			color: $black_43;
			font-size: 14px;
		}
	}
}
