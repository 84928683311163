@import '../../global.module.scss';
.toggleContainer {
	margin-bottom: 8px;
	:global {
		.ms-Toggle {
			margin-bottom: 0;
			.ms-Label i {
				line-height: 16px;
			}
		}
	}
}
