@import "../../../variables.scss";

.ratingStarsContainer {
	color: #434343;
	div {
		i {
			font-size: 18px;
		}
		i:nth-child(2) {
			padding: 0 3px;
		}
	}
	div:nth-child(1) {
		div {
			margin-top: 0;
		}
		padding: 0;
	}
	div:nth-child(2) {
		span {
			padding-left: 0;
		}
	}
	.answersList {
		margin-left: -20px;
		margin-top: 42px;
		.label {
			padding: 8px 0 0 20px;
		}
	}

	.formContainer {
		color: #434343;
		position: relative;
		:global {
			.ms-ChoiceFieldGroup-flexContainer {
				padding: 8px 0 0;
				div:nth-child(3) {
					margin-top: 0;
				}
			}
			.ms-ChoiceField-wrapper {
				.ms-ChoiceField-innerField {
					padding: 0;
					top: 8px;
					display: flex;
					justify-content: center;
				}
				.ms-ChoiceField-labelWrapper {
					font-size: 12px;
				}
				.ms-ChoiceField-imageWrapper {
					width: 100px;
					div:nth-child(1) {
						width: 52px !important;
					}
					div {
						width: 100px;
						display: flex;
						align-content: center;
						justify-content: center;
						img {
							width: 48px;
						}
					}
				}
				.ms-ChoiceField-iconWrapper {
					font-size: 20px;
				}
			}
		}
	}
}

.ratingInputField {
	border-radius: 2px;
	height: 31px;
	margin-bottom: 15px;
	opacity: 1;

	:global {
		.ms-TextField-suffix {
			background: transparent !important;
			color: inherit !important;
			padding: 6px 0 0 12px !important;
			margin: 0 !important;
		}

		input, .ms-TextField-suffix {
			border-bottom: 0 !important;
		}

		input {
			padding-top: 0 !important;
		}
	}

	label {
		background-color: transparent;
	}

	input {
		width: 53px;
	}
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		opacity: 1;
	}
}
