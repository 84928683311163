@import '../../variables.scss';
@import '../global.module.scss';

.resultGraph {
	padding-top: 14px;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	p {
		align-items: center;
		display: flex;
		flex-grow: 1;
	}
	.bar {
		background: $violet_62;
		border-radius: 5px;
		display: block;
		height: 10px;
		padding: 0 8px;
		transition: width ease-in-out 1s;
		width: 0;
	}
	.scale {
		line-height: 26px;
		padding-left: 10px;
	}
	.value {
		font-weight: 500;
	}

	.items {
		.itemAppear {
			.bar {
				width: 0 !important;
			}
		}
		.itemAppearActive {
			background-color: transparent;
		}
		.itemAppearDone {
			background-color: transparent;
		}
	}
	.item {
		padding: 0 0 13px 0;
		h6,
		p {
			font-size: 14px;
			margin: 0;
		}
	}
	:global {
		.ms-Button--action,
		.ms-Button--action:hover {
			height: 26px;
			.ms-Button-flexContainer .ms-Icon {
				color: $black_43;
			}
		}
	}
}
