@import '../../variables.scss';

.grid3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.personDetailsVoteValue {
	font-size: 16px;
	line-height: 24px;
	margin: 16px 0;
}

.memberList {
	list-style: none;
	margin: 0;
	padding: 0;

	.memberItem {
		padding-bottom: 10px;

		:global(.ms-Persona-primaryText),
		:global(.ms-Persona-secondaryText) {
			color: $black_43;
		}
	}
}
