.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loading {
	align-items: center;
	display: flex;
	justify-content: center;
	.frame {
		display: flex;
		align-items: center;
		background-color: white;
		padding: 25px 30px;
		/*box-shadow: white 0 0 15px 20px;*/
	}
	.text {
		font-size: 20px;
		margin-left: 20px;
		height: 31px;
	}
}
