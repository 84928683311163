.appsvg {
	height: 32px;
	margin-right: -4px;
	width: 32px;
	.iconNormal {
		display: block;
	}
	.iconHover {
		display: none;
	}
	&:hover {
		.iconNormal {
			display: none;
		}
		.iconHover {
			display: block;
		}
	}
}
