@import '../../variables.scss';

.dialog {
	:global {
		.ms-Dialog-title,
		.ms-Dialog-subText {
			color: $black_43;
		}
		.ms-Dialog-subText {
			padding-bottom: 24px;
			margin: 0;
		}
		.ms-Dialog-content {
			text-align: right;

			> :not(button) {
				text-align: left;
			}
		}
		.ms-Button.ms-Button--primary{
			margin-left: 20px;
		}
	}
}